jQuery(document).ready(function ($) {
    var $testimony = $('.home-testimony .swiper-container');

    if (!$testimony.length) return

    var mySwiper = new Swiper($testimony, {
        centerInsufficientSlides: true,
        slidesPerView: 3,
        slidesPerGroup: 3,
        // spaceBetween: 30,
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        navigation: {
            prevEl: $testimony.parent().find('.carousel-navigation.prev'),
            nextEl: $testimony.parent().find('.carousel-navigation.next'),
        },
        breakpoints: {
            991: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                dynamicBullets: true
            }
        }
    });
});